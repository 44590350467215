@import (less) "../../Theme/constants.css";

.icon {
  height: 144*@unit;
  background-size: contain;
  background-image: url('assets/connection-large.svg');
  margin-top: 40*@unit;
  margin-bottom: 32*@unit;
}

.cancel {
  line-height: 1.43;
  font-size: @font-size-small;
  width: 50*@unit-small;
  margin: auto;
  color: @color-text-dark;
  text-decoration: none;
  box-shadow: inset 0 -1*@unit 0 0 @color-primary-500;
  cursor: pointer;
}

.help {
  margin-bottom: 24*@unit;
}

.helpList {
  padding-left: 24*@unit;
}
