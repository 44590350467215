@import (less) "../Theme/constants.css";

.list {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  position: relative;
  margin: 8*@unit @large-text-margin 0;
  flex-shrink: 0;
  @media (--small-viewport) {
    margin: 0 16*@unit;
  }
}

.option {
  width: 100%;
  background: @color-white;
  border-radius: 4*@unit;
  border: 1px solid @color-selectors-border;
  color: @color-text-dark;
  cursor: pointer;
  display: flex;
  font: inherit;
  line-height: 1.43;
  margin-bottom: 8*@unit;
  padding: 16*@unit;
  position: relative;

  &::after {
    background: url('assets/right.svg') no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 12*@unit;
    position: absolute;
    right: 16*@unit;
    top: 50%;
    transform: translateY(-6*@unit);
    width: 8*@unit;
  }
}

.content {
  text-align: left;
  align-self: center;
  flex-basis: 100%;
}

.optionMain {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: 18*@unit;
  min-height: 48*@unit;
}

.label {
  margin: 0;
  font-weight: 600;
  line-height: 1.5;
}

.hint {
  color: @color-hint;
  font-size: @font-size-small;
  margin-bottom: 4*@unit-small;
}

.warning {
  font-weight: 600;
  font-size: @font-size-small;
  margin-bottom: 6*@unit-small;
}

.tag {
  display: inline-block;
  background-color: @color-background;
  font-size: @font-size-small;
  padding: 0 12*@unit-small;
  border-radius: 12*@unit-small;
  margin-left: -12*@unit-small;
  margin-top: 2*@unit-small;
}

.icon {
  max-width: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
  width: 48*@unit;
  height: 48*@unit;
  margin-right: 16*@unit;
  align-self: flex-start;
}

@document-types: passport, national-identity-card, driving-licence,
                 bank-building-society-statement, utility-bill, letter;

.loop(@index: 1) when (@index <= length(@document-types)) {
  @value: extract(@document-types, @index);
  .icon-@{value} {
    background-image: url('assets/icon-@{value}.svg');
  }
  .loop(@index + 1);
}
.loop();
