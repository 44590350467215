@import (less) "../../Theme/constants.css";

.container {
  width: 100%;
  border: 1px solid @color-text-dark;
  border-radius: 4*@unit;
  background-color: @color-white;
  padding: 12*@unit 14*@unit;
  display: inline-flex;
}

.switchClickableArea {
  margin-bottom: 16*@unit;
  text-decoration: none;
}

.copy {
  width: 84%;
  color: @color-text-dark;
  text-align: left;
  line-height: 1.5;
  float: left;
  align-self: center;
}

.header {
  font-weight: 600;
}

.submessage {
  margin: 0;
  font-size: @font-size-small;
  float: left;
}

.icon {
  height: 72*@unit;
  width: 72*@unit;
  min-width: 16%;
  margin-right: 14*@unit;
  -moz-border-radius: 72*@unit 72*@unit;
  -webkit-border-radius: 72*@unit 72*@unit;
  border-radius: 72*@unit 72*@unit;
  background-color: @color-icon-temporary;
  background-image: url('assets/cross-device-small-icon.svg');
  background-repeat: no-repeat;
  background-size: 42*@unit;
  background-position: 25% 50%;
  display: block;
  float: left;
}

.chevron {
  height: 12*@unit;
  width: 9*@unit;
  float: right;
  margin-left: 14*@unit;
  margin: auto;
  background-image: url('assets/chevron-right.svg');
  background-repeat: no-repeat;
}
