@import (less) "../Theme/constants.css";

.container {
  display: flex;
}

.instructions {
  margin: 16*@unit 0 0;
  @media (--small-viewport) {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center
  }
}

.instructionsCopy {
  margin: 14*@unit-small 24*@unit-small;
  font-size: @font-size-small;
  line-height: 1.43;

  @media (--small-viewport) {
    font-size: @font-size-base;
    margin: 14*@unit 24*@unit;
  }
}

.error {
  &:extend(.text);
  color: @color-error;
}

.icon {
  background-size: 64*@unit auto;
}

.buttons {
  display: flex;
  justify-content: space-around;
}

.buttonContainer {
  display: flex;
  width: 100%;
}

.button {
  display: block;
  margin: 0 5*@unit;
  flex: 1;
  padding: 0 16*@unit;

  @media (--small-viewport) {
    flex-grow: 1;
  }
}

.identityIcon {
  &:extend(.icon);
  background-image: url('assets/upload.svg');

  @media (--small-viewport) {
    background-image: url('assets/camera.svg');
  }
}

.proofOfAddressIcon {
  background-image: url('assets/letter-upload.svg');
  background-size: auto;
  height: 76*@unit;
  margin: 0 auto;
  width: 72*@unit;

  @media (--small-viewport) {
    height: 144*@unit;
    width: 144*@unit;
    background-image: url('assets/letter-focus.svg');
  }
}

.uploaderWrapper {
  display: flex;
  flex-direction: column;
  margin: 0 16*@unit;
  flex-grow: 1;
}

.uploadArea {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  padding: 24*@unit 24*@unit 8*@unit;
  border: 1px dotted @color-text-dark;
  border-radius: 4*@unit;
  background-color: @color-white;
  justify-content: space-around;

  @media (--small-viewport) {
    background-color: @color-transparent;
    border: none;
    justify-content: space-between;
    padding: 0;
  }
}
