@import (less) "../Theme/constants.css";

.loading {
  color: @color-tips-pill;
  font-size: @font-size-large;
  line-height: 1.6;
  margin: 8*@unit-large;
  float: left;
}

.mobileInput {
  font-size: @font-size-base;
}

.phoneNumberContainer {
  float: left;
  width: 100%;
  padding: 8*@unit 12*@unit;
}

.phoneNumberContainer :global {
  .react-phone-number-input__icon {
    height: 28.8*@unit;
    width: 28.8*@unit;
    border: none;
    display: block;
  }

  .rrui__select__arrow {
  color: @color-text-dark;
  }

  .rrui__select__options:not(.rrui__select__options--menu) {
    width: 438*@unit;
  }

  /*
   * We need to override the `rrui` styles from REM to EM, for our relative fonts
   */
  .rrui__input:not(.rrui__input--multiline),
  .rrui__input-label {
    height: 1.8*@unit-rrui;
  }
  .rrui__select__native {
    min-height: 1.8*@unit-rrui;
  }
  .rrui__input-error {
    margin-top: 0.3*@unit-rrui;
  }
  .rrui__select__options
  {
    padding-top: 0.6*@unit-rrui;
    padding-bottom: 0.6*@unit-rrui;
  }
  .rrui__select__options--left-aligned {
    left: -1.2*@unit-rrui;
  }
  .rrui__select__options--right-aligned {
    right: -1.2*@unit-rrui;
  }
  .rrui__select__options:not(.rrui__select__options--menu) {
    width: calc(100% + 2.4*@unit-rrui);
  }
  .rrui__select__option {
    padding-left: 1.2*@unit-rrui;
    padding-right: 1.2*@unit-rrui;
    padding-top: 0.3*@unit-rrui;
    padding-bottom: 0.3*@unit-rrui;
  }
  .rrui__select__option-icon {
    margin-right: 0.9*@unit-rrui;
  }
  .rrui__select__close-icon {
    margin-top: calc(0.6*@unit-rrui + 4px);
    margin-right: calc(0.6*@unit-rrui + 4px);
    width: 1.5*@unit-rrui;
    height: 1.5*@unit-rrui;
  }
}

.phoneNumberContainer {
  button {
    border-bottom: none;
  }

  input {
    overflow: hidden;
    width: 100%;
    border-bottom: none;
  }

  button:focus, input:focus {
    outline: auto 5px -webkit-focus-ring-color;
  }
}

.flagIcon {
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
