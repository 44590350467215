@import (less) "../../Theme/constants.css";

.pdfIcon {
  display: inline-block;
  width: 180*@unit;
  height: 180*@unit;
  max-width: 100%;
  margin: 16*@unit auto 16*@unit;

  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url('assets/pdf-success.svg');
}

.pdfWrapper,
.imageWrapper,
.videoWrapper {
  display: flex;
  position: relative;
  min-height: 90*@unit;
  margin: 0 48*@unit 32*@unit;

  @media (--small-viewport) {
    margin: 0 @small-text-margin 32*@unit;
  }
}

.imageWrapper.fullscreenImageWrapper {
  position: static;
}

.videoWrapper {
  margin-bottom: 64*@unit;
  flex: 1;
}

.pdfWrapper {
  display: block;
}

.image {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: 0 auto;
  border-radius: 3*@unit;
  flex: 0 0 auto;
  align-self: stretch;
  object-fit: contain;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
