@import (less) "../../Theme/constants.css";
@parent-width: 432;

.smsSection {
  height: 76*@unit;
  margin-top: 56*@unit;
}

.bolder {
  font-weight: 600;
}

.label {
  font-weight: 600;
  text-align: left;
  color: @color-text-dark;
  float: left;
}

.numberInputSection {
  height: 48*@unit;
  width: 100%;
  float: left;
  margin-top: 4*@unit;
}

.inputContainer, .linkText {
  .width-parent-relative(320, @parent-width);
}

.inputContainer {
  height: 100%;
  border: 1px solid @color-input-border;
  border-top-left-radius: 4*@unit;
  border-bottom-left-radius: 4*@unit;
  border-right: none;
  background-color: @color-white;
  float: left;
  box-shadow: 0 -0.1*@unit 0*@unit 0*@unit @color-input-border;
}

.fieldError {
  border: 1px solid @color-error;
  border-right: none;
  box-shadow: 0 -0.1*@unit 0*@unit 0*@unit @color-error;
}

.numberError {
  color: @color-error;
  font-size: @font-size-small;
  line-height: 1.43;
  float: left;
  margin-top: 4*@unit-small;
}

.sending:before {
  float: left;
  margin-left: 12*@unit;
  width: 16*@unit;
  height: 16*@unit;
  content: "";
  opacity: 0.5;

  background-image: url('./assets/spinner.svg');
  background-position: left center;
  background-repeat: no-repeat;

  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

.copyLinkSection {
  height: 68*@unit;
  margin-top: 86*@unit;
  text-align: left;
}

.linkContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 8*@unit;
}

.copySuccess .actionContainer:before {
  width: 13*@unit;
  height: 11*@unit;
  display: inline-block;
  justify-content: center;
  content: "";
  background-repeat: no-repeat;
  background-image: url('./assets/checkmark-green.svg');
  z-index: 1;
}

.linkText {
  resize: none;
  height: 24*@unit;
  border: none;
  background-color: @color-background;
  overflow: hidden;
  font-size: inherit;
  line-height: 1.5;
  color: @color-text-dark;
  white-space: nowrap;
  font-family: "Open Sans", sans-serif;
}

.copyToClipboard {
  float: right;
  height: 100%;
  font-size: @font-size-small;
  margin-left: 4*@unit-small;
  padding: 0;
  text-decoration: none;
  border: 1px solid transparent;
  border-bottom-color: @color-primary-500;
}

.copyToClipboard:visited {
  text-decoration: none;
  color: @color-text-dark;
  font-size: @font-size-small;
}

.copySuccess .copyToClipboard {
  border-bottom-color: transparent;
}

.divider {
  border-style: solid;
  border: 1px solid @color-border;
  opacity: 0.35;
  margin-top: 11*@unit;
}

.btn {
  height: 48*@unit;
  .width-parent-relative(112, @parent-width);
  float: right;
  border-radius: 0 4*@unit 4*@unit 0;
  padding: 0;
}
