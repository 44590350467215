@import (less) "../../Theme/constants.css";

.content {
  position: relative;
  margin: 0 40*@unit 16*@unit;
  flex: 1;
  flex-direction: column;
  display: flex;

  @media (--small-viewport) {
    margin: 0 24*@unit 16*@unit;
  }
}

.subTitle {
  font-size: @font-size-small;

  &::before {
    content: '';
    display: inline-block;
    width: 16*@unit-small;
    height: 16*@unit-small;
    background-image: url('assets/warningSmall.svg');
    background-size: contain;
    vertical-align: middle;
    line-height: 1;
    margin-right: 5*@unit-small;
    margin-top: -2*@unit-small;
  }
}

.bolder {
  font-weight: 600;
}

.makeSure {
  color: @color-text-dark;
  font-weight: 600;
  margin-bottom: 8*@unit;

  @media (--small-viewport) {
    text-align: left;
  }
}

.docImageContainer {
  position: relative;
  flex: 1;
  width: 340*@unit;
  margin: 0 auto;
  @media (--small-viewport) {
    width: 100%;
  }
}

.docImage {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.label {
  fill: @color-text-dark;
  font-size: @font-size-small;
}
