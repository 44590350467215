@import (less) "./constants.css";

.step {
  width: 100%;
  min-height: 100%;
  height: 0;/*necessary for IE11, see: https://stackoverflow.com/questions/24396205/flex-grow-not-working-in-internet-explorer-11-0*/
  position: relative;
  display: flex;
  flex-direction: column;
}


.fullScreenStep {
  overflow: hidden;
  border-radius: 8*@unit;
}

.fullHeightContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fullHeightMobileContainer {
  @media (--small-viewport) {
    .fullHeightContainer();
  }
}

.navigationBar{
  flex: 0 0 auto;
}

.content {
  /* Content height is 100% - non content height (total height of footer + total height of navbar)  */
  @non-content-height: @footer-height + @footer-margin + @navigation-height + @navigation-padding-top;
  height: calc(100% - @non-content-height);
  flex: 1 0 auto;
  position: relative;
  display: flex;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  > * {
    flex-grow: 1;
  }
  @media (--small-viewport) {
    @non-content-height: @footer-height + @footer-margin + @navigation-height-sm-screen + @navigation-padding-top-sm-screen;
    height: calc(100% - @non-content-height);
  }
}

.fullScreenContentWrapper {
  position: static;
}

.footer {
  width: 100%;
  height: @footer-height;
  margin-top: 16*@unit;
  flex: 0 0 auto;

  background-image: url('assets/powered-by-onfido.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;

  .fullScreenStep & {
    /* A full screen step will be a video/stream step, with a darker background */
    background-image: url('assets/powered-by-onfido-light.svg');
  }
}

.thickWrapper {
  margin: 0 @large-text-margin;
  @media (--small-viewport) {
    margin: 0 @small-text-margin;
  }
}

.mbottom-large {
  margin-bottom: 88*@unit;
  @media (--small-viewport) {
    margin-bottom: 40*@unit;
  }
}

.center {
  text-align: center;
}

.icon {
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  height: 64*@unit;
}

.help {
  padding: 24*@unit 16*@unit 16*@unit;
  text-align: left;
  box-shadow: inset 0 0 0 1*@unit @color-help-container;
  border-radius: 8*@unit;
}

.header {
  border-radius: 10*@unit-small;
  background-color: @color-tips-pill;
  color: @color-white;
  font-size: @font-size-small;
  font-weight: 600;
  position: absolute;
  margin-top: -10*@unit-small;
  margin-left: 10*@unit-small;
  padding: 0 14*@unit-small;
}

.helpList {
  margin: 0;
}
