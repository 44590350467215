@import (less) "../Theme/constants.css";

.previewsContainer {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16*@unit;

  &.previewsContainerIsFullScreen {
    top: 0;
  }
}

.actionsContainer {
  margin: 0 48*@unit;
  @media (--small-viewport) {
    margin: 0 @small-text-margin;
  }
}

.title {
  flex: 0 0 auto;
}

.retake {
  width: 160*@unit;
  padding: 0;
  @media (--small-viewport) {
    width: 112*@unit;
  }
}

.actions{
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .btn-primary {
    @media (--small-viewport) {
      width: 152*@unit;
    }
  }
}

.error {
  display: block;
  margin-bottom: 8*@unit;
  .btn-outline {
    width: 90%;
  }
}
