@import (less) "../Theme/constants.css";

.documentContainer {
  position: relative;
  overflow: hidden;
  margin: 0 40*@unit 16*@unit;
}

.faceContainer {
  position: static;
}
