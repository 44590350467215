@import (less) "../Theme/constants.css";

.actions {
  position: absolute;
  bottom: 48*@unit;
  left: 16*@unit;
  right: 16*@unit;
}

.btn {
  cursor: pointer;
  outline-offset: 6*@unit;
  background-color: @color-white;
  font-size: inherit;
  border-radius: 50%;
  border: 3*@unit solid @color-black;
  box-shadow: 0 0 0 4*@unit @color-white;
  height: 56*@unit;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  width: 56*@unit;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}
