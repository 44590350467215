@import (less) "../../Theme/constants.css";

.container {
  width: 100%;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: space-between;
}

.instructions {
  font-size: @font-size-small;
  margin: 10% 16*@unit-small 16*@unit-small;
  @media (--small-viewport) and (--short-viewport) {
    margin: 0 16*@unit-small;
  }
}

.image {
  font-size: @font-size-small;

  border-radius: 3*@unit-small;
  border: 1px solid @color-background;
  margin: 0 auto 8*@unit-small;
  display: flex;
  flex-direction: column;
  max-width: 280*@unit-small;
  width: 100%;
  padding: 10*@unit-small;
  @media (--small-viewport) and (--long-viewport) {
    max-width: 234*@unit-small;
  }
  @media (--small-viewport) and (--short-viewport) {
    max-width: 200*@unit-small;
  }
}

.reasons {
  font-size: @font-size-small;

  text-align: center;
  min-height: 42*@unit-small;
}

.reason {
  margin-top: 8*@unit-small;
}

.graphic {
  border: 1px solid @color-border;
  border-radius: 5*@unit-small;
  background-color: @color-white;
  box-shadow: 0 3*@unit-small 6*@unit-small @color-border;
  background-image: url('assets/allow.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  flex: 0 1 100%;
  /* 44% is the aspect ratio of responsive div */
  /* The percentage has been calculated by dividing the original height value divided by the original width value. In this case 121/270 = 0.44 */
  padding-top: 44%;
}

.allow {
  top: 70%;
  position: absolute;
  right: 0;
  text-align: center;
  width: 50%;
  font-weight: 600;

  @media (--small-viewport) {
    top: 65%;
  }
}

.allow::after {
  content: '';
  border: 4*@unit-small solid @color-primary-500;
  width: 80*@unit-small;
  height: 80*@unit-small;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (--small-viewport) and (--short-viewport) {
    width: 70*@unit-small;
    height: 70*@unit-small;
  }
}
