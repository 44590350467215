@import (less) "../Theme/constants.css";

.container {
  border-radius: 6*@unit;
  text-align: left;
  color: @color-white;
  margin: 0 40*@unit 16*@unit;
  padding: 8*@unit 16*@unit 12*@unit 16*@unit;
  position: relative;
  @media (--small-viewport) {
    margin: 0 16*@unit 8*@unit;
  }
}

.container-error {
  &:extend(.container);
  background-color: @color-error;
}

.container-warning {
  &:extend(.container);
  background-color: @color-warning;
}

.instruction {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 24*@unit;
}
.instruction-text {
  font-size: @font-size-small;
  line-height: 1.43;
}

.title {
  position: relative;
}

.title-text {
  display: inline-block;
  font-weight: 600;
  line-height: 1.5;
  padding-left: 24*@unit;
}


.title-icon {
  position: absolute;
  height: 100%;
  width: 16*@unit;
  margin-right: 8*@unit;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.title-icon-error {
  &:extend(.title-icon);
  background-image: url('assets/error.svg');
}

.title-icon-warning {
  &:extend(.title-icon);
  background-image: url('assets/warning.svg');
}

.roundedTriangle {
  position: absolute;
  bottom: -6*@unit;
  left: ~"calc(50% - 8*@{unit})";
  width: 8*@unit;
  height: 8*@unit;
  border-top: 8*@unit solid @color-transparent;
  border-right: 8*@unit solid @color-transparent;
  border-bottom: 8*@unit solid @color-transparent;
  border-left: 8*@unit solid @color-transparent;
  border-bottom-left-radius: 3*@unit;
  transform: rotate(-45deg);
}


.warningTriangle {
  border-bottom: 8*@unit solid @color-warning;
  border-left: 8*@unit solid @color-warning;
}

.errorTriangle {
  border-bottom: 8*@unit solid @color-error;
  border-left: 8*@unit solid @color-error;
}

.dismiss {
  background: url('assets/cross.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 24*@unit;
  height: 24*@unit;
  position: absolute;
  top: 10*@unit;
  right: 10*@unit;
  cursor: pointer;
  border: none;
  font: inherit;
}
