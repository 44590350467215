@import (less) "../../Theme/constants.css";

.icon {
  height: 36*@unit;
  width: 36*@unit;
  margin-right: 14*@unit;
  float: left;
  background-image: url('assets/complete-tick-small.svg');
  background-size: contain;
}

.uploadList {
  display: inline-block;
  padding-left: 0;
  margin-top: 48*@unit;
  margin-bottom: 56*@unit;
}

.uploadList li {
  margin-bottom: 24*@unit;
  list-style: none;
  text-align: left;
}

.listText {
  line-height: 2.25;
  font-weight: 600;
}
