@import (less) "../Theme/constants.css";

.challengeDescription {
  line-height: 2.25;
}

.challengeTitle {
  font-weight: 600;
  font-size: 20*@unit;
  line-height: 1.2;
}

.introCopy {
  margin-bottom: auto;
}

.introBullets {
  text-align: left;
  padding-left: 40*@unit;

  @media (--small-viewport) {
    padding-left: 0;
  }
}

.introBullet {
  padding-left: 46*@unit;
  margin-bottom: 24*@unit;
  list-style: none;
  position: relative;
}

.bolder {
  font-weight: 600;
}

.introIcon {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-size: contain;
  width: 26*@unit;
  height: 26*@unit;
}

.speak_out_loudIcon {
  background-image: url('assets/mic.svg');
  background-size: contain;
}

.two_actionsIcon {
  background-image: url('assets/camera.svg');
  background-size: contain;
}

.movement {
  background-image: url('assets/move-arrow.svg');
  background-size: contain;
  display: inline-block;
  width: 65*@unit;
  height: 21*@unit;
  margin-top: 16*@unit;
}

.movement-turnLeft {
  transform: rotate(180deg);
}

.recite {
  display: block;
  font-size: 54*@unit;
  font-weight: 600;
  line-height: 1.19;
  margin-top: 2.37*@unit;
}

.actions {
  position: absolute;
  bottom: 48*@unit;
  left: 16*@unit;
  right: 16*@unit;
}

.btn:extend(.btn, .btn-centered, .btn-primary) {
  padding: 0;
  margin-left: auto;
  margin-right: auto;
}

.captureActionsHint {
  color: @color-white;
  font-size: @font-size-small;
  margin-bottom: 10*@unit-small;

  &.recordAction {
    @media (--smaller-viewport) {
      display: none;
    }
  }
}

.caption {
  color: @color-white;
  text-align: center;
  position: absolute;
  z-index: 1;
  top: 64*@unit;
  left: 0;
  right: 0;
}

.recordingIndicator {
  text-align: center;
  margin-bottom: 16*@unit;

  /* Red circle */
  &::before {
    content: '';
    display: inline-block;
    width: 8*@unit;
    height: 8*@unit;
    margin-right: 5*@unit;
    background: @color-error;
    border-radius: 4*@unit;
    line-height: 120%;
  }
}
.recordingIndicatorText {
  font-size: @font-size-x-small;
  line-height: 1.45;
}

.fullScreenRoundedIcon() {
  cursor: pointer;
  width: 60*@unit;
  height: 60*@unit;
  font-size: inherit;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: @color-transparent;
  box-shadow: none;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}

.fullScreenStartRecordingIcon() {
  .fullScreenRoundedIcon();
  background-image: url('./assets/start.svg');
}

.fullScreenStopRecordingIcon() {
  .fullScreenRoundedIcon();
  background-image: url('./assets/stop.svg');
}

.startRecording {
  .fullScreenStartRecordingIcon();
}

.stopRecording {
  .fullScreenStopRecordingIcon();
}
