@import (less) "../Theme/constants.css";

.title {
  color: @color-text-dark;
  font-weight: 600;
  margin-bottom: 16*@unit;
}
.titleSpan {
  font-size: 30*@unit;
  line-height: 1.34;
  @media (--small-viewport) {
    font-size: 24*@unit;
  }
}

.titleSpan:focus {
  outline: none;
}

.titleWrapper {
  margin: 32*@unit @large-text-margin 16*@unit;
  flex-shrink: 0;
  @media (--small-viewport) {
    margin: 0 @small-text-margin 16*@unit;
  }
}

.smaller {
  @media (--large) {
    margin-top: 0;
  }
}

.fullScreen {
  color: @color-white;
  margin: 0;
  z-index: 1;
  .title {
    color: @color-white;
    font-weight: 400;
  }
  .titleSpan {
    font-size: @font-size-base;
    line-height: 1.5;
  }
}
